import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft"
                >
                  <div className="col-md-12">
                    <div className="about-desc">
                      <span className="heading-meta">About</span>
                      <h2 className="colorlib-heading">Who Am I?</h2>
                      <p>
                        I’m a computer engineer specialized in mobile
                        application development for iOS and Android operating
                        systems. I have more than 4 years of experience creating
                        code that allows us to enjoy amazing applications and at
                        the same time help us facilitate our daily tasks. Over
                        the last years, I’ve collaborated with several companies
                        in software development, helping them in the creation,
                        planning, development, maintenance, testing and
                        application release.
                      </p>
                      <p>
                        I consider myself a person passionate about my
                        profession and with a huge taste for technology,
                        convinced that you must love the process of learning new
                        things day by day to keep up with it as it does not
                        stop.
                      </p>
                      <p>
                        Among my hobbies and interests are good food, reading,
                        and cinema. I consider myself a person with pleasure in
                        entrepreneurship, I specialize in selling products
                        online; I keep in mind that you have to make things
                        happen and of course ... pay the price.
                      </p>
                      <p>I live in México, work in the world.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="colorlib-about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <span className="heading-meta">What I do?</span>
                <h2 className="colorlib-heading">
                  Here are some of my expertise
                </h2>
              </div>
            </div>
            <div className="row row-pt-md">
              <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                  <span className="icon">
                    <i className="icon-phone3" />
                  </span>
                  <div className="desc">
                    <h3>iOS Development </h3>
                    <p>
                      I have experience designing and developing iOS
                      applications from the ground using MVC design pattern,
                      working extensively with Swift and sometimes Objective-C,
                      Xcode and Interface Builder for the applications at hand.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                  <span className="icon">
                    <i className="icon-phone3" />
                  </span>
                  <div className="desc">
                    <h3>Android Development</h3>
                    <p>
                      I have experience in mobile development phases like
                      designing, enhancements, bug-fixing, and maintenance of
                      the applications. Also integrating web services, XML
                      parsing and JSON.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                  <span className="icon">
                    <i className="icon-phone3" />
                  </span>
                  <div className="desc">
                    <h3>React Native Development</h3>
                    <p>
                      I have experience building hybrid cross-platform
                      applications using this incredible framework. I’ve
                      designed React Native components and worked with forms,
                      events, keys, and navigation.
                    </p>
                  </div>
                </div>
              </div>
              {/*
                  <div className="col-md-4 text-center animate-box">
                      <div className="services color-2">
                      <span className="icon">
                          <i className="icon-data" />
                      </span>
                      <div className="desc">
                          <h3>Dev Ops</h3>
                          <p>Jenkins , Kubernetes , Docker </p>
                      </div>
                      </div>
                  </div>
                  <div className="col-md-4 text-center animate-box">
                      <div className="services color-4">
                      <span className="icon">
                          <i className="icon-layers2" />
                      </span>
                      <div className="desc">
                          <h3>Graphic Design</h3>
                          <p>My friend knows .. P</p>
                      </div>
                      </div>
                  </div>
                  <div className="col-md-4 text-center animate-box">
                      <div className="services color-6">
                      <span className="icon">
                          <i className="icon-phone3" />
                      </span>
                      <div className="desc">
                          <h3>Digital Marketing</h3>
                          <p>I use Instagram eight hours a day :) </p>
                      </div>
                      </div>
                  </div>
                  */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
